import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Form, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { WOOD_CODE } from 'server/wood-code';
import { honoClient } from './honoClient';

export default function SortSetting() {
	const specCateQuery = useQuery({
		queryKey: ['specCate'],
		queryFn: async () => {
			const res = await honoClient.specCate.$get({
				json: {},
			});
			return await res.json();
		},
	});

	const latestSortQuery = useQuery({
		queryKey: ['latestSortQuery'],
		queryFn: async () => {
			const res = await honoClient.lastSortSetting.$get({
				json: {},
			});
			return await res.json();
		},
	});

	return (
		<div className="p-2 grid grid-cols-2">
			{latestSortQuery.isFetched && (
				<Form
					initialValues={{
						specifications: latestSortQuery?.data?.specifications?.join(',\n'),
						// ?.replaceAll(
						// 	/\[\]/g,
						// 	'',
						// ),
					}}
					onFinish={async (v) => {
						const str = v.specifications
							.replaceAll(/[\r\n]+/g, '')
							.split(',')
							.map((a) => (a.indexOf('"') !== -1 ? a.trim() : `"${a.trim()}"`))
							.join(',');

						const sps = JSON.parse(`[${str}]`);

						const res = await honoClient.saveSortSetting.$post({
							json: {
								specifications: sps,
							},
						});

						const opRes = await res.json();

						if (opRes.code === WOOD_CODE.OK) {
							message.success('成功');
							await latestSortQuery.refetch();
							return;
						}

						message.error('失败');
					}}
				>
					<Form.Item
						label="排序输入框"
						help="用英文逗号','分隔"
						name="specifications"
					>
						<TextArea rows={30} />
					</Form.Item>

					<Button htmlType="submit">提交</Button>
				</Form>
			)}

			<div className="p-2">
				<div>当前启用排序uuid: {latestSortQuery?.data?.uuid}</div>
				<div className="pb-2 text-lg">所有 specifications 种类:</div>
				<div className="">
					{specCateQuery?.data?.map((a) => {
						return <div key={a?.specifications}>{a?.specifications},</div>;
					})}
				</div>
			</div>
		</div>
	);
}
